:root {
  --background-color: #000000;
  --primary-color: #ffffff;
  --secondary-color: rgba(255, 255, 255, 0.5);
  --accent-1: #C93FEC;
  --accent-2: #7346E8;
  --accent-3: #c93fec86;
  --dim-accent-1: rgba(201, 63, 236, 0.6);
  --dim-accent-2: rgba(115, 70, 232, 0.6);
}

@font-face {
  font-family: Satoshi;
  src: url(./Satoshi-Variable.ttf);
}
*{
  scroll-behavior: smooth;
}
body {
  background: var(--background-color);
  color: var(--primary-color);
  font-family: Satoshi;
}
.home{
  background: #170900;
  min-height: 100vh;
}

a {
  text-decoration: none;
}

.mainlogo {
  width: 50px;
  z-index: 110;
  background: transparent;
  position: relative;
}

.mainlogowrap {
  background: transparent !important;
}

nav {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
  padding-top: 2rem;
}

nav h2 {
  margin: 0;
  font-size: 22px;
  letter-spacing: 2px;
  background: linear-gradient(90deg, var(--dim-accent-1), var(--dim-accent-2));
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

nav .link,
nav a {
  position: relative;
  color: var(--primary-color);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding-inline: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  margin-inline: 10px;
  border-radius: 5px;
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
}

.link:hover,
nav a:hover {
  color: var(--accent-1) !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.linkactive {
  color: var(--accent-2) !important;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.discover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  width: 40%;
  height: 80px;
  background: linear-gradient(90deg, var(--dim-accent-1), var(--dim-accent-2));
  border: 1px solid rgba(0, 0, 0, 0.281);
  box-shadow: 0px 5px 20px 1px rgba(201, 63, 236, 0.4);
}

.discover .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
}

.col-text {
  flex-direction: column;
  display: flex;
  gap: 5px;
  margin-left: 10px;
}

.col-text h5 {
  color: var(--secondary-color);
  font-size: 11px;
  margin: 0;
  text-align: left;
  font-weight: 300;
  letter-spacing: 3px;
}

.col-text h4 {
  color: var(--primary-color);
  font-size: 20px;
  margin: 0;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.discover img {
  width: 120px;
  border-radius: 5px;
  transform: translateY(-25px);
}

.discover .right {
  padding-right: 10px;
}

.loadscr {
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: var(--background-color);
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
}

@keyframes moveout {
  0% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  50% {
    transform: translateY(0px);
    opacity: 1;
    z-index: 100;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
    display: none;
  }
}

.loadscr img {
  width: 20%;
}

.discover-button {
  background: white;
  text-decoration: none;
  color: var(--background-color);
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

}

.discover-button h4 {
  font-size: 14px;
  font-weight: 800;
  margin: 0;
}

.discover-button:hover svg {
  transform: translateX(10px);
}

.discover-button svg {
  width: 12px;
  transition: 0.2s ease-in-out;
}

.hide-800 {
  display: flex;
}

.menu-button {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;

}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: var(--primary-color);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.8px);
  -webkit-backdrop-filter: blur(12.8px);
  /* From https://css.glass */
  background: rgba(0, 0, 0, 0.65);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.1px);
  -webkit-backdrop-filter: blur(10.1px);
  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: white;
  font-size: 20px;
  font-weight: 800;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}

.social-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.social-mobile img {
  width: 20px;
}

.buy-container {
  z-index: -100;
  transition: 0.2s all ease-in;
  opacity: 0;
  display: none;
}

.open-buy {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  margin-left: 10px;
  opacity: 1;
  z-index: 110;
}

.open-buy-desk {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 180px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  z-index: 110;
  opacity: 1;
  position: absolute;
  margin-left: 235px;
  margin-top: 40px;
  gap: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.open-buy-desk .buy-link {
  background: transparent;
}

.open-buy-desk .buy-link img {
  width: 14px;
}

.buy-link {
  display: flex;
  gap: 10px;
  opacity: 1;
  z-index: 110;
}

.buy-link img {
  width: 20px;
  transform: rotate(-50deg);
}

.discover-cards {
  width: 85%;
  margin-inline: auto;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.discover-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5%;
  user-select: none;
  z-index: 2;

}

.discover-card .left h3 {
  font-size: 120px;
  margin: 0;
}

.discover-card .right {
  width: 100%;
  background: rgba(175, 173, 173, 0.1);
  height: 200px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  box-shadow: 0px 5px 20px 1px rgba(201, 63, 236, 0.2);
  transition: 0.2s all ease-in-out;
  position: relative;

}

.discover-card .right h4 {
  font-size: 30px;
  font-weight: 800;
  margin: 0;
}

.discover-card .right img,
.discover-card .right h4 {
  opacity: 0.1;
  transition: 0.2s all ease-in-out;
}

.discover-card .right:hover img,
.discover-card .right:hover h4 {
  opacity: 1;
}

.discover-card .right:hover {
  box-shadow: 0px 5px 20px 1px rgba(201, 63, 236, 0.3);

}

.desk {
  gap: 10px;
}

.eligibility {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  position: relative;
  z-index: 10;
}

.status {
  font-size: 22px;
  font-weight: 800;
  text-shadow: 1px 1px 1px var(--dim-accent-1);
}

.valid {
  color: rgb(38, 156, 38);
}

.invalid {
  color: rgb(255, 42, 42);
}

input {
  width: 500px;
  height: 40px;
  border-radius: 5px;
  border: none;
  padding-left: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
  background: rgba(51, 51, 51);
  box-shadow: 0px 5px 20px 1px rgba(201, 63, 236, 0.3);
  transition: 0.2s all ease-in-out;
}

.checkstatus {
  border-radius: 20px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 30px;
  color: var(--primary-color);
  background: black;
  box-shadow: 0px 5px 20px 1px rgba(201, 63, 236, 0.4);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  border: 1px solid rgba(201, 63, 236, 0.6);
}

.checkstatus:hover {
  padding: 10px 40px;
  box-shadow: 0px 3px 20px 1px rgba(201, 63, 236, 0.6);
}

.information h2 {
  text-align: center;
}

.information {
  margin-top: 100px;
}

.desk-image-container {
  height: 85vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  width: 820px;
}

.check-allow {
  position: absolute;
  color: white;
  top: 0;
  z-index: 2;
  left: -25%;
  font-size: 30px;
  font-weight: 500;
  padding: 5px 30px;
  border-radius: 10px;
  transition: 0.2s all ease-in-out;
  background: black;
}


.desk-image-container2 {
  height: 80vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
}

.desk-image {
  height: 100%;
}

.desk-image1 {
  height: 100%;
  position: absolute;
  left: -10%;
  z-index: 1;
  opacity: 0.8;
}

.desk-image2 {
  height: 100%;
  position: absolute;
  right: -10%;
  z-index: 1;
  opacity: 0.8;
}

.fade-left {
  height: 100%;
  width: 200px;
  background: linear-gradient(to left, transparent, black);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.fade-right {
  height: 100%;
  width: 200px;
  background: linear-gradient(to right, transparent, black);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.fade-top {
  height: 200px;
  width: 100%;
  background: linear-gradient(to top, transparent, black);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;
}

.big-card {
  height: 80vh;
  width: 90%;
  border: 1px solid var(--accent-3);
  margin-inline: auto;
  margin-top: 5vh;
  position: relative;
  border-right: 1px solid transparent;
  min-width: 1100px;
}

.square1 {
  width: 20px;
  height: 20px;
  background: var(--accent-1);
  position: absolute;
  top: 0;
  left: 0;
  animation: glow infinite alternate 1s;
}

.square2 {
  width: 20px;
  height: 20px;
  background: var(--accent-1);
  position: absolute;
  top: 0;
  right: 0;
  animation: glow infinite alternate 1s;
}

.square-circle1 {
  width: 20px;
  height: 20px;
  background: var(--accent-1);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top-right-radius: 100%;
  animation: glow infinite alternate 1s;
}
@keyframes glow{
  0%{
    background: var(--accent-1);
  }
  100%{
    background: var(--accent-3);
  }
}

.inner-big-card {
  position: relative;
  height: calc(100% - 60px);
  width: 60%;
  margin-left: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid var(--accent-3);
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  min-width: 640px;
  border-right: 1px solid transparent;

}
.line-top{
  position: absolute;
  top: 20px;
  left: 0;
  height: 1px;
  width: 100%;
  background: var(--accent-3);
}
.right-label {
  position: absolute;
  right: 0;
  top: 40px;
  height: calc(100% - 60px);
  border: 1px solid var(--accent-3);
  border-top: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.right-label h2{
  transform: rotate(90deg);
  white-space: nowrap;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 10px;
}
.inner-big-card h1{
  margin-left: 40px;
}
.mini-info h2{
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  border-bottom: 1px solid rgba(144, 54, 185, 0.329);
}
.mini-info p{
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}
.mini-info{
  max-width: 350px;
}
.grid-info{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding-inline: 30px;
  min-width: 580px;
}
.line-mid{
  position: absolute;
  top: 20px;
  left: calc(60% + 21px);
  height: calc(100% - 20px);
  width: 1px;
  background: var(--accent-3);
}
.flex-inner{
  display: flex; 
  height: 100%;
  min-width: 1100px;
}
.inner-second{
  width: calc(40% - 30px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-width: 400px;

}
.inner-part{
  width: 80%;
  margin-inline: auto;
  border-radius: 15px;
  background: #1f1b24;
  padding: 15px;
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.inner-part h1{
  font-size: 26px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
}
.inner-part h2{
  font-size: 18px;
  margin-bottom: 0px;
}
.inner-part p{
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}
.partners{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.partners-grid{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  min-width: 65rem;
  margin-top: 30px;
}
.partner{
  width: 120px;
}
.partner img{
  width: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apply{
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
  margin-inline: auto;
  border: 1px solid var(--accent-3);
  margin-bottom: 50px;
  transition: 0.2s all ease;
}
.apply:hover{
  box-shadow: 0px 0px 50px #c93fec62;
}
.btn{
  width: 150px;
  height: 40px;
  background: var(--accent-1);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-inline: auto;
  color: white;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}
.apply a{
  width: 100%;
  text-decoration: none;
  color: white;
  padding-block: 10px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  transition: 0.2s all ease;
}
.btn:hover{
  background: var(--accent-3);
}
.apply a:hover{
  gap: 20px;
}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

@media(max-width: 1160px) {
  .menu-button {
    display: flex;
  }

  body {
    overflow-x: hidden;
  }

  .home {
    overflow-x: hidden;
    width: 100%;
  }

  .desk-image-container {
    overflow-x: hidden;
    width: 100%;
  }

  input {
    width: 90%;
    font-weight: 600;
    font-size: 12px;
  }

  .hide-800 {
    display: none;
  }

  .home {
    height: 100vh;
  }

  .discover {
    width: 90%;
    left: 0;
    right: 0;
    margin-inline: auto;
  }

  .discover img {
    width: 100px;
    transform: translateY(-15px);
  }

  .discover-button {
    padding: 10px 10px;
  }

  .col-text {
    gap: 0px;
    margin-left: 5px;
  }

  .col-text h5 {
    font-size: 10px;
    letter-spacing: 2px;
  }

  .discover-left {
    padding-left: 3px;
  }

  .discover .right {
    padding-right: 7px;
  }

  .discover-card .left {
    display: none;
  }

  .discover-card .right {
    flex-direction: column-reverse;
  }

  .discover-card .right img,
  .discover-card .right h4 {
    opacity: 1;
    transition: 0.2s all ease-in-out;
  }

  .discover-card .right img {
    width: 150px;
    position: relative;
  }

  .discover-card .right h4 {
    font-size: 20px;
  }

  .check-allow {
    font-size: 20px;
    padding: 5px 20px;
    left: 0;
    right: 0;
    margin-inline: auto;
    width: 100px;
    top: 100px;
  }

  .desk-image1 {
    display: none;
  }

  .desk-image2 {
    right: 0;
    left: 0;
  }

  @keyframes boxanim {
    0% {
      box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
    }

    25% {
      box-shadow: 1px 1px 10px rgba(255, 255, 255, 0);
    }

    50% {
      box-shadow: 1px 1px 10px var(--accent-1);
    }

    75% {
      box-shadow: 1px 1px 10px rgba(255, 255, 255, 0);
    }
  }

  .information {
    width: 90%;
    left: 0;
    right: 0;
    margin-inline: auto;
  }

  .desk-image {
    z-index: 2;
    width: 100%;
    height: auto;
  }
  .desk-image-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .partners-grid{
    width: 90%;
    min-width: auto;
    flex-direction: column;
    gap: 50px;
  }
  .partner img{
    width: 100%;
  }
  .partner{
    width: 150px;
  }
  .flex-inner{
    flex-direction: column;
    gap: 20px;
    height: auto;
    min-width: auto;
    width: 97%;
  }
  .grid-info{
    min-width: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-inline: 0;
    margin-inline: auto;
  }
  .inner-big-card{
    width: 100%;
    height: auto;
    min-width: auto;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: 8px;
    margin-top: 28px;
  }
  .big-card{
    width: 95%;
    height: auto;
    min-width: 0;
  }
  .inner-second{
    width: 100%;
    min-width: 0;
    margin-inline: auto;
    gap: 30px;
    margin-bottom: 30px;

  }
  .mini-info{
    max-width: auto;
    width: 95%;
  }
  .line-mid{
    display: none;
  }
  .right-label{
    display: none;
  }
  .btn{
    position: relative;
    bottom: 0;
    margin-bottom: 10px;
    margin-top: 40px;
  }
}